.timetableContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  --left-bar-width: 42px;
  margin: 16px 0;
}
.weekdayRow {
  display: flex;
  margin-left: var(--left-bar-width);
  margin-bottom: 10px;
  font-size: 14px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .weekdayText {
    color: var(--text);
    opacity: 1;
    :global(.caption) {
      opacity: 1;
      font-size: 11px;
      margin-top: 4px;
      opacity: 0.7;
    }
  }
}
.timetableCanvas {
  display: flex;
  flex: 1;
}
.timetableTicks {
  display: flex;
  flex-direction: column;
  width: calc(var(--left-bar-width) - 10px);
  font-size: 12px;
  padding-right: 10px;
  text-align: right;
  opacity: 0.7;
  .timeLineBox {
    flex: 1;
    line-height: 14px;
    margin-top: -7px;
  }
}
.timetableCoursesContainer {
  flex: 1;
  position: relative;
  display: flex;
  border-right: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}

@media only screen and (max-width: 560px) {
  .timetableContainer {
    --left-bar-width: 42px;
    width: 126vw;
  }
}
