.timetableCourseCard {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  z-index: 1;
  background-color: var(--background);
  transform: scale(1);
  transition: transform 0.3s;
  user-select: none;
  padding: 4px 6px;
  .delete {
    display: none;
  }
  &:hover {
    height: fit-content !important;
    transform: scale(1.2);
    z-index: 10;
    .delete {
      display: inline-flex;
      position: absolute;
      z-index: 12;
      bottom: 2px;
      right: 2px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.timetableCourseCardTitle {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  text-align: initial;
}

.timetableCourseCardLocation {
  margin-top: 2px;
  font-size: 10px;
  text-align: initial;
}
