.ttOverviewListItem {
  flex-direction: row;
  :global(.list-item-title-container) {
    margin-left: 0;
    :global(.caption) {
      display: flex;
      align-items: center;
      svg {
        margin-left: 4px;
        margin-right: 4px;
        font-size: 16px;
      }
    }
  }
  .btnContainer {
    svg {
      padding: 4px;
    }
  }
}

.menuItem {
  &:global(.MuiMenuItem-root) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.timetableSelectionMenu {
  --menu-padding-horizontal: 16px;
  --menu-padding-vertical: 6px;
  & :global(.MuiPaper-root) {
    // Menu contianer
    :global(.MuiSvgIcon-root) {
      // may not working for nested global?
      font-size: 22px;
    }
    :global(.MuiList-root) {
      padding: 0;
    }
    h4 {
      margin: 12px var(--menu-padding-horizontal);
      color: var(--primary);
    }
  }
  & .timetableLabelInputContainer {
    margin: 0 var(--menu-padding-horizontal) var(--menu-padding-vertical) var(--menu-padding-horizontal);
    > :global(.MuiInputBase-root) {
      flex: 1;
    }
  }
  & :global(.MuiButtonBase-root):last-child {
    color: var(--primary);
    font-weight: bold;
  }
}
