.homeCourseContainer {
  height: fit-content;
  overflow: visible;
  .homeCourseListItem {
    height: auto;
    border-bottom: 1px solid var(--border);
    align-items: flex-start;
  }
  & > a {
    &:last-child {
      .homeCourseListItem {
        border-bottom: none;
      }
    }
  }
}
.homeCourseListItem {
  & > span {
    margin: 0 16px;
  }
}
