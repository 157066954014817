.plannerFooter {
  margin-top: 4px;
}

.plannerShareDialog {
  .shareLinkRow {
    height: 46px;
    :global(.copy) {
      height: 100%;
      margin-left: 6px;
      width: 72px;
    }
  }
}

.plannerInputContainer {
  flex: 1;
}

@media only screen and (max-width: 1260px) {
  .plannerTimetableContainer {
    max-width: 100vw;
    width: 100%;
  }
  .plannerFooter {
    display: none;
  }
  .plannerInputContainer {
    width: 100%;
  }
  .plannerShareDialog {
    :global(.MuiPaper-root) {
      margin: 0;
    }
    .contentContainer {
      width: calc(90vw - 2 * var(--card-padding));
    }
  }
}
