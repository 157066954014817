.homePage {
  background-color: var(--background);
  height: 100%;
}

.homePageLeft {
  height: 0;
  width: 300px;
  position: sticky;
  top: calc(var(--header-height) + var(--page-margin));
}

.homePageRight {
  flex: 1;
  height: fit-content;
}

.homePageTimetable {
  min-height: calc(100vh - 202px);
  margin-left: 0;
}

.linksCard {
  padding: var(--card-padding);
  row-gap: 8px;
  :global(.sub-title) {
    margin-bottom: 8px;
  }
}

.homeLinkContainer {
  font-size: 14px;
  &:hover {
    color: var(--primary);
  }
  svg {
    margin-right: 8px;
  }
}

@media only screen and (max-width: 1260px) {
  .homePage {
    :global(.center-page) {
      flex-direction: column;
      overflow-y: visible;
      overflow-x: hidden;
    }
    :global(.tabs-row) {
      height: auto;
      flex-shrink: 0;
      width: 100%;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
  }
  .homePageLeft {
    position: relative;
    height: fit-content;
    width: 100%;
    top: 0;
  }
  .homePageRight {
    width: 100%;
    margin-top: var(--card-padding);
    margin-left: 0;
  }
}
