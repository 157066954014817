.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.footerLink {
  color: var(--caption);
  font-size: 12px;
  margin: 6px 12px;
  opacity: 0.6;
  transition: ease-in-out 0.1s opacity;
  &:hover {
    opacity: 1;
  }
}
