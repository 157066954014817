.userCard {
  height: fit-content;
}
.userCardHeader {
  padding: var(--card-padding);
  .charIcon {
    background-color: var(--primary);
    color: white;
    text-transform: uppercase;
  }
  :global(.title) {
    font-size: 16px;
    margin-bottom: auto;
    span {
      margin-left: 8px;
      cursor: pointer;
    }
  }
  :global(.caption) {
    font-size: 12px;
  }
  button {
    margin-left: auto;
    color: var(--text);
    svg {
      font-size: 20px;
    }
  }
}

.userCardHeaderDetails {
  height: 36px;
  margin-left: 8px;
}

.userAboutCard {
  margin-top: var(--card-padding);
  padding: var(--card-padding);
  padding-top: 0;
  flex-direction: row;
  & > div {
    flex: 1;
    :global(.sub-title) {
      margin-bottom: 6px;
    }
  }
}
