.timetablePanel {
  & > header {
    justify-content: space-between;
    :global(.title) {
      font-size: 18px;
    }
    .btnRow {
      & > button {
        margin-left: 24px;
        color: var(--text);
        :global(.MuiButton-label) {
          text-transform: capitalize;
        }
        :global(.MuiSvgIcon-root) {
          font-size: 22px;
        }
      }
    }
  }
}

// Animation

.iconSpin {
  -webkit-animation: iconSpin 1s infinite linear;
  animation: iconSpin 1s infinite linear;
}

@-webkit-keyframes iconSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes iconSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 1260px) {
  .timetablePanel {
    padding: var(--card-padding);
    overflow-x: scroll;
    & > header {
      position: sticky;
      top: 0;
      left: 0;
    }
  }
}
