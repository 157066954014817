.loginPage {
  flex: 1;
}

.loginPanel {
  margin: auto;
  background-color: var(--surface);
  border-radius: var(--border-radius);
  padding: 36px;
  height: auto;
  width: 50%;
  max-width: 400px;
  font-size: 16px;
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  :global(.title) {
    margin-bottom: 10px;
  }
  :global(.caption) {
    opacity: 0.6;
    line-height: 24px;
  }
  .goBackIcon {
    color: var(--text);
    margin: 0 10px 0 -16px;
  }
  :global(.textFieldLabel) {
    margin-left: 0;
    color: var(--text);
    margin-bottom: -6px;
    cursor: initial;
  }
}
.label {
  font-size: 14px;
  cursor: pointer;
  margin-left: 12px;
  font-weight: bold;
}
.loginInputContainer {
  margin-top: 0;
}
.titleRow {
  margin-bottom: 8px;
}
.forgotPwdRow {
  margin-top: 4px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  > .forgotPwdLabel {
    margin-left: auto;
  }
}
.loginBtn {
  height: 50px;
  margin-top: 10px !important;
  border-radius: var(--border-radius) !important;
  background: var(--default-button-background) !important;
  box-shadow: none !important;
  & > :global(.MuiButton-label) {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 18px;
  }
  :global(.MuiCircularProgress-svg) {
    color: white;
  }
}
.switchContainer {
  margin-top: 10px;
  font-size: 14px;
}

@media only screen and (max-width: 1260px) {
  .loginPage {
    background-color: var(--surface);
  }
  .loginPanel {
    width: calc(100% - 72px);
    padding: 0;
  }
}
